<template>
  <div class="root">
    <Banner
      title="Newsletter"
      image="img/headers/newsletter.jpg"
      :icon="newsletter.icon"
    />
    <div class="container mx-auto py-12 text-center">
      <h1 class="uppercase">Habla</h1>
      <hr class="mx-auto" />
      <p class="text-lg lg:px-40">{{ newsletter.text }}</p>
    </div>
    <div class="container mx-auto mb-16">
      <div class="flex-newsletter-container">
        <div
          class="newsletter-box flex flex-wrap justify-center gap-x-8 gap-y-16"
        >
          <div
            v-for="(doc, idx) in filteredNewsletters"
            :key="idx"
            class="newsletter-item text-center w-72 flex-initial"
          >
            <img class="w-full h-96 bg-gray-300" :src="doc.preview" />
            <h4 class="text-2xl font-black mt-4 mb-2 text-blue-400">
              {{ doc.title }}
            </h4>
            <p class="mb-8">{{ doc.date }}</p>
            <a
              :disabled="!doc.link"
              :href="doc.link"
              class="
                disabled:bg-blue-100
                text-xl
                inline-block
                w-full
                h-16
                pt-4
                align-middle
                font-light
                text-white
                rounded-full
                focus:outline-none
                bg-blue-400
                hover:bg-blue-500
                cursor-pointer
              "
            >
              Descargar
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import { newsletter } from "@/vars";

export default {
  components: {
    Banner,
  },
  computed: {
    filteredNewsletters: function () {
      const validQueryparam = this.$route.query.pass == 'Wj8d%4z6RSeCUk@;' || false
      console.log(validQueryparam)
      if (validQueryparam) {
        return this.newsletter.docs;
      } else {
        return this.newsletter.docs.filter((doc) => !doc.internal);
      }
    },
  },
  setup() {
    return {
      newsletter,
    };
  },
};
</script>

<style scoped>
</style>
