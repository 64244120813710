<template>
  <div class="relative h-96 bg-white flex justify-center overflow-hidden">
    <img
      class="absolute object-cover object-center w-full h-full"
      :src="image"
      :alt="title"
    />
    <div class="text-container w-full relative">
      <div class="bg absolute w-full h-64 flex justify-center"></div>
      <div class="absolute w-full h-full flex justify-center">
        <div class="w-full flex justify-center lg:px-24 items-center">
          <img
            class="p-4 h-35 w-36 mr-auto lg:border-r-2"
            :src="icon"
            alt="logo"
          />
          <h1
            class="
              ml-0
              mx-auto
              uppercase
              self-center
              font-bold
              text-white
              font-sans
            "
          >
            {{ title }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["image", "title", "icon"],
};
</script>
<style scoped>
.text-container > .bg {
  top: calc(50% - 8rem);
  background-color: rgb(41, 159, 255);
  mix-blend-mode: multiply;
}
</style>